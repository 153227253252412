@import "@Static/css/common";
@import "@Static/css/effects";

// theme colours
$color1: getColorValue(1, (56, 36, 242));
$color2: getColorValue(2, (255, 255, 255));
$color3: getColorValue(3, (51, 51, 51));

.colourScheme1 {
    color: $color1;
    background-color: $color2;
    border-color: $color1 !important;
    &.hoverable:hover {
        color: $color2;
        background-color: $color1;
    }
}

.colourScheme2 {
    color: $color2;
    background-color: $color1;
    border-color: $color1 !important;
    &.hoverable:hover {
        color: $color1;
        background-color: $color2;
    }
}

.root {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 2;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;

    background-color: $color2;
    color: $color3;
}

.navbarItem {
    &:not(.coloured) {
        color: $color3;
    }
    text-decoration: none !important;
    margin-left: 20px;
    cursor: pointer;
    transition: background-color 0.1s linear;
}

.button {
    cursor: pointer;
}

.homeButton {
    display: flex;
    align-items: center;
    margin: 0;
}

.rightContainer {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 1200px) {
    .desktop {
        display: none;
    }
}

@media only screen and (min-width: 1201px) {
    .mobile {
        display: none;
    }
}

.desktop {
    .button {
        border: 2px solid;
        border-radius: 8px;
        padding: 4px 10px;
    }

    .eventListingsButton {
        position: relative;
        &:hover,
        &:active {
            .eventListingsDropdownContainer {
                display: flex;
            }
        }
    }

    .eventListingsDropdownContainer {
        margin-top: 5px;
        display: none;
        flex-direction: column;
        background-color: $color2;
        padding: 10px 0;
    }

    .eventListingsDropdownItem {
        padding: 5px 20px;
        margin: 0;
        &:hover,
        &:active {
            color: $color2;
            background-color: $color3;
        }
    }
}

.mobileRoot {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: calc(100vh - 100%);
    padding: 8px 0;
    background-color: $color2;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2) inset;
    user-select: none;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;

    @extend .expandY;

    overflow-y: auto;
    @extend .themedScrollbar;
}

.mobile {
    .mobHamMenuButton {
        display: flex;
        padding: 6px 12px;
        margin-left: 6px;
        border-radius: 1000rem;
        cursor: pointer;
        display: block;
        height: 32px;
    }

    .navbarItem {
        padding: 2px 0;
        margin: 2px 20px;
    }

    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 0;
        margin: 10px;
        border-radius: 6px;
    }

    hr {
        margin: 10px 20px;
    }

    .eventListingsButton {
        display: flex;
        align-items: center;
    }

    .eventListingsDropdownContainer {
        flex-direction: column;
        &.open {
            display: flex;
        }
        &:not(.open) {
            display: none;
        }
    }
}
